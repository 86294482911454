import { LocaleProvider, ValidationProvider } from '@typescript-kit/core';
import { welcomeDeLocale } from './locale/de.locale';
import { WelcomeValidationKey } from './key/welcome-validation.key';
import { passwordRequirementValidationPredicate } from './validation/password-requirement.validation-predicate';

export class WelcomeInitializer {

  static initialize() {
    WelcomeInitializer.registerLocales();
    WelcomeInitializer.registerValidationPredicates();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'welcome', welcomeDeLocale);
  }

  static registerValidationPredicates() {
    ValidationProvider.registerPredicate(WelcomeValidationKey.PASSWORD_REQUIREMENT, passwordRequirementValidationPredicate);
  }
}
